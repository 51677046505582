
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const store = useStore()
    const isAdminLoggedIn = computed(() => store.state.admin)
    const data = ref({ email: '', password: '' })
    const error = ref(null)
    const login = async () => {
      const { email, password } = data.value
      const response = await store.dispatch('login', { email, password })
      if (!response.success) {
        error.value = response.data
      }
    }
    const removeError = () => (error.value = null)
    const goBack = () => (location.href = '/')
    return {
      isAdminLoggedIn,
      data,
      error,
      login,
      goBack,
      removeError,
    }
  },
})
