<template>
  <div>
    <router-view v-if="isAdminLoggedIn" />
    <div class="modal is-active" v-else>
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Login</p>
        </header>
        <section class="modal-card-body">
          <div class="notification is-danger" v-if="error">
            <button class="delete" @click="removeError"></button>
            {{ error }}
          </div>
          <div class="field">
            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                <input
                  type="email"
                  class="input"
                  v-model="data.email"
                  required
                />
              </div>
            </div>
            <div class="field">
              <label class="label">Password</label>
              <div class="control">
                <input
                  type="password"
                  class="input"
                  v-model="data.password"
                  required
                />
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" @click="login">Login</button>
          <button class="button" @click="goBack">Go Back</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const store = useStore()
    const isAdminLoggedIn = computed(() => store.state.admin)
    const data = ref({ email: '', password: '' })
    const error = ref(null)
    const login = async () => {
      const { email, password } = data.value
      const response = await store.dispatch('login', { email, password })
      if (!response.success) {
        error.value = response.data
      }
    }
    const removeError = () => (error.value = null)
    const goBack = () => (location.href = '/')
    return {
      isAdminLoggedIn,
      data,
      error,
      login,
      goBack,
      removeError,
    }
  },
})
</script>
